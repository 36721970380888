<template>
    <div class="footer">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-4">
                    <div class="footer-title">
                        {{ this.lang.header.item2 }}
                    </div>
                    <div class="footer-item">
                        <router-link to="/about-us">{{ this.lang.header.item8 }}</router-link>
                    </div>
<!--                     <div class="footer-item">
                        {{ this.lang.header.item3 }}
                    </div>
                    <div class="footer-item">
                        {{ this.lang.header.item9 }}
                    </div> -->
                </div>
                <div class="col-12 col-md-4">
                    <div class="footer-title">
                        {{ this.lang.header.item10 }}
                    </div>
                    <div class="footer-item d-flex align-items-center">
                        <i style="margin-right: 10px; color: #6a686d;" class="fas fa-phone-alt"></i>
                        <a href="tel:+38 0960028826">+38 096 00 2882 6</a>
                    </div>
                    <div class="footer-item d-flex align-items-center">
                        <i style="margin-right: 10px; color: #6a686d;" class="far fa-envelope"></i>
                        <a href="mailto:info@pinikay.com.ua">info@pinikay.com.ua</a>

                    </div>
                    <div class="footer-item d-flex align-items-center">
                        <i style="margin-right: 10px; color: #6a686d;" class="fab fa-telegram-plane"></i>
                        <a href="https://t.me/pinikayUA" target="_blank" rel="noopener noreferrer">@pinikayUA</a>
                    </div>
                </div>
                <div class="col-12 col-md-4 last-block">
                    <div class="footer-title mb-0">
                        {{ this.lang.header.item11 }}
                    </div>
                    <div class="footer-item pt-0" v-html="this.lang.header.item12">
                    </div>
                    <div class="position-relative" style="width: max-content" v-if="form_sended == false">
                        <i class="fas fa-arrow-circle-right"
                            style="position: absolute; right: 5px; font-size: 20px; top: 10px; color:#d9d7d7; cursor: pointer;"
                            @click="send_form()"></i>
                        <input v-model="email" class="email-input" type="text" placeholder="Your email"
                            v-on:keyup.enter="send_form()">
                    </div>
                    <div style="color: green" v-if="form_sended == true">
                        Дякуємо за підписку
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            accessToken: null,
            email: null,
            form_sended: false,
        }
    },
    props: [
        'lang'
    ],
    mounted() {
        let those = this
        axios({
            method: 'post',
            url: 'https://api.sendpulse.com/oauth/access_token',
            data: {
                "grant_type": "client_credentials",
                "client_id": "b0bce180c843ac3236db0fd2cc72d510",
                "client_secret": "35db6cb9f96cd9f2ca02edaa5bacdee5"
            }
        }).then(function (response) {

            those.accessToken = response.data.access_token
        })
    },
    methods: {
        send_form() {
            if (this.email !== null) {
                let those = this
                let input = document.querySelector('.email-input');
                input.setAttribute('disabled', true)
                let data_to_send = {
                    "emails": [
                        {
                            "email": `${this.email}`
                        }
                    ]
                };
                axios({
                    method: 'post',
                    url: `https://api.sendpulse.com/addressbooks/${those.lang.main.address_id_email}/emails`,
                    headers: `Authorization: Bearer ${those.accessToken}`,
                    data: data_to_send
                }).then(function (response) {
                    console.log(response)
                    those.form_sended = true
                })
            }

        }
    }
}
</script>

<style lang="scss">
.footer {
    background: #252828;
    padding: 20px;
}

.footer-title {
    font-size: 20px;
    font-weight: 800;
    color: #6a686d;
    margin: 10px 0;
}

.footer-item {
    color: #fff;
    padding: 5px 0;

    a {
        color: #fff;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            color: #fb7e30;
        }
    }
}

.email-input {
    border: 1px solid grey;
    background: #fff;
    padding: 8px;
    border-radius: 25px;
    outline: none;

}

//@media screen and (max-width: 600px) {
//    .last-block {
//        padding-bottom: 120px !important;
//    }
// }
</style>
