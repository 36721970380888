<template>
    <div class="header" @click="dropdownHandler">
        <div class="container-fluid" :class="{ active: mobile_nav }">
            <div class="row justify-content-between align-items-center pb-2">
                <div class="col-4">
                    <router-link to="/">
                        <img class="main-logo"
                            :src="require('@/assets/images/Karpiuk Pinikay паливні брикети пінікей пиникей брекети дрова  (8).png')">
                    </router-link>

                </div>
                <div class="d-none d-md-flex col-md-4 col-lg-5 row justify-content-end">
                    <div class="nav-item" @mouseenter="this.dropdown = 1" @mouseleave="this.dropdown = 0">
                        {{ this.lang.item1 }}
                        <div class="nav-dropdown" v-if="this.dropdown == 1">
                            <router-link class="dropdown-item" to="/fuel-boilers">
                                {{ this.lang.item4 }}
                            </router-link>
                            <router-link class="dropdown-item" to="/fireplaces">
                                {{ this.lang.item5 }}
                            </router-link>
                            <router-link class="dropdown-item" to="/ovens">
                                {{ this.lang.item6 }}
                            </router-link>
                            <router-link class="dropdown-item" to="/barbeques">
                                {{ this.lang.item7 }}
                            </router-link>
                        </div>
                    </div>
                    <div class="nav-item" @mouseenter="this.dropdown = 2" @mouseleave="this.dropdown = 0">
                        {{ this.lang.item2 }}
                        <div class="nav-dropdown" v-if="this.dropdown == 2">
                            <router-link class="dropdown-item" to="/about-us">
                                {{ this.lang.item8 }}
                            </router-link>
                            <!--                             <router-link class="dropdown-item" to="/">
                                {{ this.lang.item9 }}
                            </router-link>
                            <router-link class="dropdown-item" to="/">
                                {{ this.lang.item10 }}
                            </router-link> -->
                        </div>
                    </div>
                    <div class="nav-item" @click="anchor_link()">
                        {{ this.lang.item3 }}
                    </div>
                    <div class="lang_bl" @mouseenter="locale_open = true" @mouseleave="locale_open = false">
                        <img :src="require(`../assets/images/${user_locale}.png`)" alt="">
                        <div class="position-absolute row w-100 pt-1" v-if="locale_open == true">
                            <img :src="require(`../assets/images/en.png`)" alt="" v-if="user_locale == 'ua'"
                                @click="setLang('en')">
                            <img :src="require(`../assets/images/ua.png`)" alt="" v-if="user_locale == 'en'"
                                @click="setLang('ua')">
                        </div>
                    </div>
                </div>
                <div class="d-flex d-md-none col-2" @click="mobile_nav = !mobile_nav">
                    <!-- <img style="width: 100%;" :src="require('../assets/images/burger-nav.png')" alt=""> -->
                    <i style="color: #fff; font-size: 35px" class="fas fa-bars"></i>
                </div>
            </div>

        </div>
        <Transition name="nested">
            <div class="mobile-nav inner" v-if="mobile_nav">
                <div class="nav-item col" data-dropdown="1" @click="dropdownHandler">
                    {{ this.lang.item1 }}
                    <div class="mx-2 dropdown-mob" v-if="this.dropdown == 1">
                        <router-link class="dropdown-item" to="/fuel-boilers" @click="(this.mobile_nav = false)">
                            {{ this.lang.item4 }}
                        </router-link>
                        <router-link class="dropdown-item" to="/fireplaces" @click="(this.mobile_nav = false)">
                            {{ this.lang.item5 }}
                        </router-link>
                        <router-link class="dropdown-item" to="/ovens" @click="(this.mobile_nav = false)">
                            {{ this.lang.item6 }}
                        </router-link>
                        <router-link class="dropdown-item" to="/barbeques" @click="(this.mobile_nav = false)">
                            {{ this.lang.item7 }}
                        </router-link>
                    </div>
                </div>
                <div class="nav-item col" data-dropdown="2" @click="dropdownHandler">
                    {{ this.lang.item2 }}
                    <div class="mx-2 dropdown-mob" v-if="this.dropdown == 2">
                        <router-link class="dropdown-item" to="/about-us" @click="(this.mobile_nav = false)">
                            {{ this.lang.item8 }}
                        </router-link>
                        <!--                         <router-link class="dropdown-item" to="/">
                            {{ this.lang.item9 }}
                        </router-link>
                        <router-link class="dropdown-item" to="/">
                            {{ this.lang.item10 }}
                        </router-link> -->
                    </div>
                </div>
                <div class="nav-item col" @click="anchor_link()">
                    {{ this.lang.item3 }}
                </div>
                <div class="lang_bl m-4" @mouseenter="locale_open = true" @mouseleave="locale_open = false">
                    <img :src="require(`../assets/images/${user_locale}.png`)" alt="">
                    <div class="position-absolute row p-0" v-if="locale_open == true"
                        style="width: 76px; top: 0; right: -150%;">
                        <img class="p-0" :src="require(`../assets/images/en.png`)" alt="" v-if="user_locale == 'ua'"
                            @click="setLang('en')">
                        <img class="p-0" :src="require(`../assets/images/ua.png`)" alt="" v-if="user_locale == 'en'"
                            @click="setLang('ua')">
                    </div>
                </div>
            </div>
        </Transition>
    </div>

</template>

<script>
export default {
    props: [
        'lang'
    ],
    data() {
        return {
            mobile_nav: false,
            dropdown: 0,
            user_locale: 'ua',
            locale_open: false,
        }
    },
    created() {
        let locale;
        localStorage.getItem('lang') == null ? locale = 'ua' : locale = localStorage.getItem('lang')
        this.user_locale = locale
    },
    methods: {
        dropdownHandler: function (e) {
            let item = e.target
            this.dropdown = item.dataset.dropdown
        },
        setLang(lang) {
            localStorage.setItem('lang', lang)
            window.location.reload()
        },
        anchor_link() {
            this.$emit('anchor')
            this.mobile_nav = false
        }
    }
}

</script>

<style lang="scss" scoped>
.lang_bl {
    width: 50px;
    cursor: pointer;
    position: relative;

    img {
        width: 100%;
    }
}

.nested-enter-active .inner,
.nested-leave-active .inner {
    transition: all 0.3s ease-in-out;
}

.nested-enter-from .inner,
.nested-leave-to .inner {
    transform: translateX(30px);
    opacity: 0;
}

.header {
    padding: 10px 25px 0;
    position: sticky;
    top: 0;
    z-index: 22;
    background: #252828;

    .main-logo {
        width: 50px;

    }

    .nav-item {
        font-family: Fixel;
        font-weight: 600;
        height: max-content;
        cursor: pointer;
        transition-duration: .3s;
        color: #fff;
        position: relative;
        width: max-content !important;

        &:hover {
            color: #fb7e30;

        }
    }

    .mobile-nav {
        position: absolute;
        top: 71px;
        background: #252828;
        width: 100%;
        left: 0;

        .nav-item {
            padding: 8px 25px;
        }
    }


    .container-fluid.active {
        border-bottom: 2px solid #000;
        padding-bottom: 10px
    }

    .nav-dropdown {
        position: absolute;
        top: 100%;
        background: rgb(54, 54, 54);
        padding: 5px 8px;
        color: #fff;
        z-index: 3;

        .dropdown-item {
            &:hover {
                color: #fb7e30;
            }
        }
    }

    .dropdown-mob {
        .dropdown-item {
            color: #fff;
            opacity: 0.9;
        }
    }
}

@media screen and (max-width: 600px) {

    /*     .lang_bl {
        width: 100px;
    } */
    .lang_bl {

        img {
            width: 50px;
            height: 30px;
        }
    }
}
</style>