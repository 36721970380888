<template>
  <Header :lang="this.lang_arr[0].header" @anchor="anchor_link()" />
  <router-view :lang="this.lang_arr[0]" />
  <Footer :lang="this.lang_arr[0]"/>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import lang_json from "@/assets/lang/locale.json"

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      lang_arr: {}
    }
  },
  created() {
    let locale ;
    localStorage.getItem('lang') == null ? locale = 'ua' : locale = localStorage.getItem('lang')
    this.lang_arr = lang_json[locale]
  },
  methods: {
    anchor_link() {
      let hiddenElement = document.querySelector('.form-component')
      hiddenElement.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }
}

</script>

<style lang="scss">
@font-face {
  font-family: Fixel;
  src: url("./assets/fonts/MacPawFixel-VF.ttf");
}

* {
  font-family: Fixel;
  color: #404644;
}

</style>
