import { createRouter, createWebHistory } from 'vue-router'

const routes = [

  {
    path: '/',
    name: 'home',
    component: function () {
      return import('../views/Home.vue')
    }
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: function () {
      return import('../views/Nav-1.vue')
    }
  },
  {
    path: '/fuel-boilers',
    name: 'fuel-boilers',
    component: function () {
      return import('../views/Nav-2.vue')
    }
  },
  {
    path: '/fireplaces',
    name: 'fireplaces',
    component: function () {
      return import('../views/Nav-3.vue')
    }
  },
  {
    path: '/ovens',
    name: 'ovens',
    component: function () {
      return import('../views/Nav-4.vue')
    }
  },
  {
    path: '/barbeques',
    name: 'barbeques',
    component: function () {
      return import('../views/Nav-5.vue')
    }
  },
  {
    path: '/nav-6',
    name: 'nav-6',
    component: function () {
      return import('../views/Nav-6.vue')
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
